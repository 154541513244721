import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { cloneDeep, isEqual, set } from "lodash";
import { Moment } from "moment";
import { extendMoment, MomentRangeStaticMethods } from "moment-range";
import { useHotelsRooms } from "../hooks/hotelsRooms";
import { useLazyB2bIncludedPois } from "../hooks/lazyB2bIncludedPois";
import { useGetCircuitProductsFromItineraryType } from "../utils/getCircuitProductsFromItineraryType";
import { Circuit } from "../../Itinerary/objects/circuit";
import { IataAirline } from "../../CartMaterial/objects/iataAirline";
import { Itinerary } from "../../Itinerary/objects/itinerary";
import { PricePeriod } from "../objects/pricePeriod";
import { PriceTerrestrial } from "../objects/priceTerrestrial";
import { PriceFlight } from "../objects/priceFlight";
import { IataAirport } from "../../Itinerary/objects/iataAirport";
import { TripVersion } from "../../Menu/MaterialTripList/objects/tripVersion";
import { HotelRooms } from "../objects/hotelRooms";
import { ManualProductFlightCart } from "../../Itinerary/objects/manualProductFlightCart";
import { AccommodationCart } from "../../Itinerary/objects/accommodationCart";
import { AdditionnalProduct } from "../hooks/hotelAdditionnalProducts";
import { ProductPrice } from "../../Itinerary/objects/productPrice";
import { Flight } from "../../Itinerary/network/flight";
import { ForfaitPrice } from "../objects/forfaitPrice";
import { ItineraryInput } from "../../Itinerary/objects/itineraryState";
import { PoiAvailability, ScheduledPrice } from "../hooks/lazyB2bPoiAvailabilities";
import { PoiSearchData } from "../hooks/lazyB2bPois";
import { HotelSearchData } from "../hooks/lazyB2bHotels";
import { Transfer } from "../../Transfers/objects/Transfer";
import { TravelExchangeOptions } from "../hooks/lazyB2bTravelExchangeOptions";
import { AdalteOptions } from "../hooks/lazyB2bAdalteOptions";

const moment = extendMoment(window.moment as any);

export enum DestinationTab {
    ALL,
    PACKAGES,
    CUSTOM
}

export type TravelerErrorMessage = {
    travelerId: number,
    code: 'first-name-required' |
    'last-name-required' |
    'same-names' |
    'age-changed' |
    'phone-email-required' |
    'email-required' |
    'phone-required',
    message: string
}

type PrecartFlightPriceDetail = {
    number: 2,
    taxes: ProductPrice[],
    total_price: string
}

export type CircuitSliceState = {
    headerHeight: number,
    package: {
        data: Circuit | null,
        version: number | null,
        versions: TripVersion[],
        variant: number | null,
        itinerary: Itinerary[],
        periods: PricePeriod[] | null,
        priceTerrestrial: PriceTerrestrial[] | null,
        priceFlight: PriceFlight[] | null,
        forfaitPrice: ForfaitPrice[] | null,
        pricesLoaded: boolean,
        vatAmount: number,
        flightBoParams: {
            allow_manual_flight: boolean,
            allowed_departure_airport: IataAirport[],
            period: {
                start_date: string,
                end_date: string
            }[] | null
        }[] | null,
    },
    inputs: Pick<
        CircuitSliceState['precart'],
        'departureCity' | 'startDate' | 'duration' | 'rooms' | 'transportsPreferences' | 'periodId'
    > & {
        itineraryInputs: ItineraryInput[],
    },
    precart: {
        mode: 'creation' | 'edit',
        referenceDate: (MomentRangeStaticMethods & Moment) | null,
        startDate: string | null,
        nextAvailableDays: {
            [date: string]: boolean
        },
        periodId: number | null,
        departureCity: IataAirport | null,
        rooms: {
            adultsCount: number,
            childrenCount: number,
            childrenAges: number[],
        }[],
        applicationFees: {
            price: number
        } | null,
        transportsPreferences: {
            flightClass: number,
            airlines: IataAirline[],
            onlyDirectFlights: boolean,
            isHoneyMoon: boolean,
            isWeddingAnniversary: boolean
        },
        room_price: ReturnType<typeof useHotelsRooms>['response'],
        includedPoisList: Awaited<ReturnType<ReturnType<typeof useLazyB2bIncludedPois>>>,
        duration: number | null,
        flight: {
            type: 'online',
            data: {
                id: number,
                flight_key: string,
                total_price: string,
                outbounds: Flight['outbounds'],
                last_ticketing_date: string | null,
                adult: PrecartFlightPriceDetail | null,
                baby: PrecartFlightPriceDetail | null,
                children: PrecartFlightPriceDetail | null,
                day_gap: number,
            }[]
        } | {
            type: 'offline',
            data: ManualProductFlightCart
        } | null,
        enabledOptionalProducts: ({
            type: 'normal' | 'manual',
            id: number,
            productType: number,
            prices: ProductPrice[],
            poiCompo?: {
                adultIndexes: number[],
                childIndexes: number[],
                variantName: string | null,
                price: ScheduledPrice | null,
                customTime: string | null
            }[]
        } | {
            type: 'additionnal-product',
            hotel?: AccommodationCart['hotel'][number],
            data: AdditionnalProduct
        })[],
        enabledOptionalProductsInputs: CircuitSliceState['precart']['enabledOptionalProducts'],
        selectedInsurance: ({
            type: 'normal' | 'manual',
            id: number,
            name: string,
            prices: ProductPrice[]
        } | {
            type: 'additionnal-product',
            products: AdditionnalProduct[]
        }) | null,
        selectedAdalteOptions: (AdalteOptions['rooms'][number]['options'][number] & Pick<AdalteOptions['rooms'][number], 'index'> | null)[],
        selectedAdaltePickup: AdalteOptions['pickups'][number] | null,
        selectedAdalteLanguage: string | null,
        selectedTravelExchangePrice: TravelExchangeOptions['travel_exchange_prices'][number] | null,
        selectedTravelExchangeOptions: TravelExchangeOptions['travel_exchange_price_options'],
        selectedTravelExchangeMealPlan: {
            name: string,
            price: number
        } | null,
        selectedRooms: (
            (
                Omit<HotelRooms['rooms'][number], 'variants'> & {
                    passengerGroupIndex: number,
                    variant: HotelRooms['rooms'][number]['variants'][number],
                    hotel?: AccommodationCart['hotel'][number] | HotelSearchData,
                    accommodation?: AccommodationCart,
                    provider?: {
                        id: number,
                        isCustom: boolean
                    }
                }
            ) | null
        )[],
        poiTravelerIndices: {
            [id: PoiSearchData['id']]: number[]
        },
        selectedPois: {
            data: PoiSearchData,
            availability: PoiAvailability['scheduled_prices'][number] & {
                providerId: number,
                isCustom: boolean
            },
            hour: string | null,
        }[][],
        selectedTransfers: (
            Transfer & {
                provider_id: number,
                custom_provider: boolean,
                startDate: string,
                productQuantity: number,
                searchData: Record<string, unknown>
            }
        )[],
        selectedCars: ({
            type: 'online',
            contract_info: {
                selected_car: {
                    provider: string,
                    vehicle_info: {
                        name: string
                    }
                }
            },
            car_contract: unknown,
            step_info: {
                origin_date: string
            }[],
            location_index: number,
            car_index: unknown,
            agency_options: unknown,
            travelers: unknown,
        } | {
            type: 'offline',
            name: string,
            start_date: string
        })[],
        moreProductLists: {
            pois: {
                [destinationId: number]: {
                    realCount: number,
                    data: PoiSearchData[]
                }
            },
            hotels: {
                [destinationId: number]: {
                    realCount: number,
                    data: HotelSearchData[]
                }
            }
        },
        productsAvailabilities: {
            hotels: {
                [hotelId: number]: HotelRooms[]
            },
            pois: {
                [poiId: string]: PoiAvailability | null
            }
        },
        products: Awaited<ReturnType<typeof useGetCircuitProductsFromItineraryType>>,
        passengersGroups: {
            groups: any[],
            withApplicant: boolean,
            tempContactLead: any | null,
            tempTravelers: any[],
            moreInfo: any[],
            infoIncomplete: any[]
        },
        nextRoom: number,
        travelersErrorMessages: TravelerErrorMessage[],
        unlockedSteps: { [step: number]: boolean },
        modalErrors: {
            key: string,
            message: string
        }[]
    },
    flightsSearchPage: {
        providersObj: any,
        searched_group: any,
        editSearch: boolean,
        loadingRequest: boolean,
        countProviders: number
    }
}

const initialState: CircuitSliceState = {
    headerHeight: 0,
    package: {
        data: null,
        version: null,
        versions: [],
        variant: null,
        itinerary: [],
        periods: null,
        priceFlight: null,
        priceTerrestrial: null,
        forfaitPrice: null,
        pricesLoaded: false,
        vatAmount: 0,
        flightBoParams: null,
    },
    inputs: {
        itineraryInputs: [],
        departureCity: null,
        duration: null,
        periodId: null,
        rooms: [
            {
                adultsCount: 2,
                childrenCount: 0,
                childrenAges: []
            }
        ],
        startDate: null,
        transportsPreferences: {
            flightClass: 0,
            airlines: [],
            onlyDirectFlights: false,
            isHoneyMoon: false,
            isWeddingAnniversary: false
        },
    },
    precart: {
        mode: 'creation',
        startDate: null,
        nextAvailableDays: {},
        referenceDate: null,
        periodId: null,
        departureCity: null,
        rooms: [
            {
                adultsCount: 2,
                childrenCount: 0,
                childrenAges: []
            }
        ],
        transportsPreferences: {
            flightClass: 0,
            airlines: [],
            onlyDirectFlights: false,
            isHoneyMoon: false,
            isWeddingAnniversary: false
        },
        room_price: [],
        includedPoisList: [],
        duration: null,
        flight: null,
        enabledOptionalProducts: [],
        enabledOptionalProductsInputs: [],
        selectedInsurance: null,
        selectedTravelExchangePrice: null,
        selectedTravelExchangeOptions: [],
        selectedTravelExchangeMealPlan: null,
        selectedAdalteLanguage: null,
        selectedAdalteOptions: [],
        selectedAdaltePickup: null,
        selectedRooms: [],
        selectedPois: [],
        selectedTransfers: [],
        selectedCars: [],
        moreProductLists: {
            hotels: {},
            pois: {}
        },
        productsAvailabilities: {
            hotels: {},
            pois: {}
        },
        poiTravelerIndices: {},
        products: {
            accommodations: [],
            cars: [],
            pois: [],
            transfers: [],
            flights: [],
            trains: [],
            cruises: [],
            ferries: [],
            assistances: [],
            insurances: [],
            manualProducts: []
        },
        passengersGroups: {
            groups: [],
            infoIncomplete: [],
            moreInfo: [],
            tempContactLead: null,
            tempTravelers: [],
            withApplicant: true
        },
        applicationFees: null,
        nextRoom: 0,
        travelersErrorMessages: [],
        unlockedSteps: {},
        modalErrors: []
    },
    flightsSearchPage: {
        providersObj: {},
        searched_group: null,
        editSearch: false,
        loadingRequest: false,
        countProviders: 0
    }
}

const slice = createSlice({
    name: 'circuit',
    initialState,
    reducers: {
        addModalErrorMessage(
            state,
            action: PayloadAction<CircuitSliceState['precart']['modalErrors'][number]>
        ): void {
            state.precart.modalErrors.push(action.payload);
        },
        addItemsInMoreList(
            state: CircuitSliceState,
            action: PayloadAction<{
                key: keyof CircuitSliceState['precart']['moreProductLists'],
                destinationId: number,
                data: CircuitSliceState['precart']['moreProductLists'][keyof CircuitSliceState['precart']['moreProductLists']][number]['data']
            }>
        ): void {
            const currentItem = state.precart.moreProductLists[action.payload.key][action.payload.destinationId] ?? {
                realCount: 0,
                data: []
            };
            state.precart.moreProductLists = {
                ...state.precart.moreProductLists,
                [action.payload.key]: {
                    ...state.precart.moreProductLists[action.payload.key],
                    [action.payload.destinationId]: {
                        ...currentItem,
                        data: currentItem.data.concat(action.payload.data)
                    }
                }
            };
        },
        addSelectedTransfer(
            state: CircuitSliceState,
            action: PayloadAction<{
                transfer: CircuitSliceState['precart']['selectedTransfers'][number]
            }>
        ): void {
            state.precart.selectedTransfers.push(action.payload.transfer);
        },
        addSelectedCar(
            state: CircuitSliceState,
            action: PayloadAction<{
                car: CircuitSliceState['precart']['selectedCars'][number]
            }>
        ): void {
            state.precart.selectedCars.push(action.payload.car);
        },
        changeHeaderHeight(
            state,
            action: PayloadAction<number>
        ): void {
            state.headerHeight = action.payload;
        },
        changePrecartFlight(
            state,
            action: PayloadAction<any>
        ): void {
            if (state.precart.flight?.type === 'online') {
                state.precart.flight.data![6] = action.payload;
            }
        },
        deleteModalErrorMessage(
            state,
            action: PayloadAction<CircuitSliceState['precart']['modalErrors'][number]['key']>
        ): void {
            const index = state.precart.modalErrors.findIndex((item) => {
                return item.key === action.payload;
            });
            state.precart.modalErrors.splice(index, 1);
        },
        deleteOptionalAdditionnalProduct(
            state: CircuitSliceState,
            action: PayloadAction<AdditionnalProduct>
        ): void {
            state.precart.enabledOptionalProducts = state.precart.enabledOptionalProducts.filter((item) => {
                return item.type !== 'additionnal-product' ||
                    !isEqual(item.data, action.payload);
            });
        },
        moveUpSelectedItemsInMoreList(
            state: CircuitSliceState,
            action: PayloadAction<{
                destinationId: number,
                stepIndex: number
            }>
        ): void {
            state.precart.moreProductLists = {
                hotels: {
                    ...state.precart.moreProductLists.hotels,
                    [action.payload.destinationId]: {
                        ...state.precart.moreProductLists.hotels[action.payload.destinationId] ?? {
                            realCount: 0,
                            data: []
                        },
                        data: [...state.precart.moreProductLists.hotels[action.payload.destinationId]?.data ?? []].map((item) => {
                            return {
                                ...item,
                                minimalPrices: state.precart.selectedRooms[action.payload.stepIndex]?.hotel?.id === item.id ?
                                    item.minimalPrices.map((price) => ({
                                        ...price,
                                        timestamp: 0
                                    })) :
                                    item.minimalPrices.map((price) => ({
                                        ...price,
                                        timestamp: Date.now()
                                    }))
                            }
                        })
                    }
                },
                pois: {
                    ...state.precart.moreProductLists.pois,
                    [action.payload.destinationId]: {
                        ...state.precart.moreProductLists.pois[action.payload.destinationId] ?? {
                            realCount: 0,
                            data: []
                        },
                        data: [...state.precart.moreProductLists.pois[action.payload.destinationId]?.data ?? []].map((item) => {
                            const itemId = item.is_custom ?
                                item.id :
                                item.product_code;
                            return {
                                ...item,
                                minimalPrices: state.precart.selectedPois[action.payload.stepIndex]?.some((poi) => {
                                    const poiId = poi.data.is_custom ?
                                        poi.data.id :
                                        poi.data.product_code;
                                    return poiId === itemId;
                                }) ?
                                    item.minimalPrices.map((price) => ({
                                        ...price,
                                        timestamp: 0
                                    })) :
                                    item.minimalPrices.map((price) => ({
                                        ...price,
                                        timestamp: Date.now()
                                    }))
                            }
                        })
                    }
                },
            }
        },
        resetCircuit: (state): void => {
            const newState = cloneDeep(initialState);
            const tempContactLead = state.precart.passengersGroups.tempContactLead;
            for (const key in newState) {
                set(state, key, newState[key as keyof typeof newState]!);
            }
            state.precart.passengersGroups.tempContactLead = tempContactLead;
        },
        removePoi(
            state: CircuitSliceState,
            action: PayloadAction<{
                index: number,
                poi: CircuitSliceState['precart']['selectedPois'][number][number]['data']
            }>
        ): void {
            state.precart.selectedPois[action.payload.index] = state.precart.selectedPois[action.payload.index]?.filter((item) => {
                return item.data.id !== action.payload.poi.id;
            }) ?? [];
        },
        removeTransfer(
            state: CircuitSliceState,
            action: PayloadAction<{
                transfer: CircuitSliceState['precart']['selectedTransfers'][number]
            }>
        ): void {
            state.precart.selectedTransfers = state.precart.selectedTransfers.filter((item) => {
                return item.identifier !== action.payload.transfer.identifier;
            });
        },
        removecar(
            state: CircuitSliceState,
            action: PayloadAction<{
                car: {
                    name: string,
                    startDate: string
                }
            }>
        ): void {
            state.precart.selectedCars = state.precart.selectedCars.filter((item) => {
                const name = item.type === 'online' ?
                    item.contract_info.selected_car.vehicle_info.name :
                    item.name;
                const date = item.type === 'online' ?
                    item.step_info[0]?.origin_date :
                    item.start_date;
                const itemId = `${name}-${date}`;
                return itemId !== `${action.payload.car.name}-${action.payload.car.startDate}`;
            });
        },
        setPackage<K extends keyof CircuitSliceState['package']>(
            state: CircuitSliceState,
            action: PayloadAction<{
                key: K,
                value: CircuitSliceState['package'][K],
            }>
        ): void {
            state.package[action.payload.key] = action.payload.value;
        },
        setPreCart<K extends keyof CircuitSliceState['precart']>(
            state: CircuitSliceState,
            action: PayloadAction<{
                key: K,
                value: CircuitSliceState['precart'][K],
            }>
        ): void {
            state.precart[action.payload.key] = action.payload.value;
        },
        setInputs<K extends keyof CircuitSliceState['inputs']>(
            state: CircuitSliceState,
            action: PayloadAction<{
                key: K,
                value: CircuitSliceState['inputs'][K],
            }>
        ): void {
            state.inputs[action.payload.key] = action.payload.value;
        },
        setPassengersGroups<K extends keyof CircuitSliceState['precart']['passengersGroups']>(
            state: CircuitSliceState,
            action: PayloadAction<{
                key: K,
                value: CircuitSliceState['precart']['passengersGroups'][K],
            }>
        ): void {
            state.precart.passengersGroups[action.payload.key] = action.payload.value;
        },
        setReferenceDate(
            state: CircuitSliceState,
            action: PayloadAction<CircuitSliceState['precart']['referenceDate']>
        ): void {
            state.precart.referenceDate = action.payload;
        },
        setSelectedAdalteOption(
            state: CircuitSliceState,
            action: PayloadAction<{
                index: number,
                option: CircuitSliceState['precart']['selectedAdalteOptions'][number]
            }>
        ): void {
            if (state.precart.selectedAdalteOptions.length - 1 < action.payload.index) {
                state.precart.selectedAdalteOptions = state.precart.selectedAdalteOptions.concat(
                    new Array(action.payload.index - (state.precart.selectedAdalteOptions.length - 1)).fill(null)
                );
            }

            state.precart.selectedAdalteOptions[action.payload.index] = action.payload.option;
        },
        setSelectedRoom(
            state: CircuitSliceState,
            action: PayloadAction<{
                index: number,
                room: CircuitSliceState['precart']['selectedRooms'][number]
            }>
        ): void {
            if (state.precart.selectedRooms.length - 1 < action.payload.index) {
                state.precart.selectedRooms = state.precart.selectedRooms.concat(
                    new Array(action.payload.index - (state.precart.selectedRooms.length - 1)).fill(null)
                );
            }

            state.precart.selectedRooms[action.payload.index] = action.payload.room;
        },
        setSelectedPoi(
            state: CircuitSliceState,
            action: PayloadAction<{
                index: number,
                poi: CircuitSliceState['precart']['selectedPois'][number][number]
            }>
        ): void {
            if (state.precart.selectedPois.length - 1 < action.payload.index) {
                state.precart.selectedPois = state.precart.selectedPois.concat(
                    new Array(action.payload.index - (state.precart.selectedPois.length - 1)).fill([])
                );
            }

            state.precart.selectedPois[action.payload.index] = [action.payload.poi].concat(
                state.precart.selectedPois[action.payload.index]?.filter((item) => {
                    return item.data.id !== action.payload.poi.data.id;
                }) ?? []
            );
        },
        setFlightsSearchPage<K extends keyof CircuitSliceState['flightsSearchPage']>(
            state: CircuitSliceState,
            action: PayloadAction<{
                key: K,
                value: CircuitSliceState['flightsSearchPage'][K],
            }>
        ): void {
            state.flightsSearchPage[action.payload.key] = action.payload.value;
        },
        setProductAvailabilities<
            K extends keyof CircuitSliceState['precart']['productsAvailabilities'],
            IdKey extends keyof CircuitSliceState['precart']['productsAvailabilities'][K]
        >(
            state: CircuitSliceState,
            action: PayloadAction<{
                key: K,
                id: IdKey,
                data: CircuitSliceState['precart']['productsAvailabilities'][K][IdKey]
            }>
        ): void {
            state.precart.productsAvailabilities[action.payload.key] = {
                ...state.precart.productsAvailabilities[action.payload.key],
                [action.payload.id]: action.payload.data
            };
        },
        setUnlockedStep(
            state: CircuitSliceState,
            action: PayloadAction<number>
        ): void {
            state.precart.unlockedSteps[action.payload] = true;
        },
        setItemsInMoreList(
            state: CircuitSliceState,
            action: PayloadAction<{
                key: keyof CircuitSliceState['precart']['moreProductLists'],
                destinationId: number,
                data: CircuitSliceState['precart']['moreProductLists'][keyof CircuitSliceState['precart']['moreProductLists']][number]
            }>
        ): void {
            state.precart.moreProductLists = {
                ...state.precart.moreProductLists,
                [action.payload.key]: {
                    ...state.precart.moreProductLists[action.payload.key],
                    [action.payload.destinationId]: action.payload.data
                }
            };
        },
        updateItemsInMoreList(
            state: CircuitSliceState,
            action: PayloadAction<{
                key: keyof CircuitSliceState['precart']['moreProductLists'],
                destinationId: number,
                data: CircuitSliceState['precart']['moreProductLists'][keyof CircuitSliceState['precart']['moreProductLists']][number]['data']
            }>
        ): void {
            const currentItem = state.precart.moreProductLists[action.payload.key][action.payload.destinationId] ?? {
                realCount: 0,
                data: []
            };
            state.precart.moreProductLists = {
                ...state.precart.moreProductLists,
                [action.payload.key]: {
                    ...state.precart.moreProductLists[action.payload.key],
                    [action.payload.destinationId]: {
                        ...currentItem,
                        data: currentItem.data.map((poi) => {
                            const updateItem = action.payload.data.find((item) => {
                                return item.id === poi.id;
                            });

                            if (updateItem) {
                                return {
                                    ...poi,
                                    minimalPrices: poi.minimalPrices.concat(updateItem.minimalPrices)
                                };
                            }

                            return poi;
                        })
                    }
                }
            };
        }
    }
});

export const {
    addModalErrorMessage,
    addItemsInMoreList,
    addSelectedCar,
    addSelectedTransfer,
    changeHeaderHeight,
    changePrecartFlight,
    deleteModalErrorMessage,
    deleteOptionalAdditionnalProduct,
    moveUpSelectedItemsInMoreList,
    resetCircuit,
    removePoi,
    removeTransfer,
    removecar,
    setUnlockedStep,
    setReferenceDate,
    setSelectedAdalteOption,
    setSelectedRoom,
    setSelectedPoi,
    setItemsInMoreList,
    updateItemsInMoreList
} = slice.actions;

export default slice;

export const setProductAvailabilities = slice.actions.setProductAvailabilities as <
    K extends keyof CircuitSliceState['precart']['productsAvailabilities'],
    IdKey extends keyof CircuitSliceState['precart']['productsAvailabilities'][K]
>(payload: {
    key: K,
    id: IdKey,
    data: CircuitSliceState['precart']['productsAvailabilities'][K][IdKey]
}) => PayloadAction<{
    key: K,
    id: IdKey,
    data: CircuitSliceState['precart']['productsAvailabilities'][K][IdKey]
}>;

export const setPreCart = slice.actions.setPreCart as <
    K extends keyof CircuitSliceState['precart'],
>(payload: {
    key: K,
    value: CircuitSliceState['precart'][K],
}) => PayloadAction<{
    key: K,
    value: CircuitSliceState['precart'][K],
}>;

export const setInputs = slice.actions.setInputs as <
    K extends keyof CircuitSliceState['inputs'],
>(payload: {
    key: K,
    value: CircuitSliceState['inputs'][K],
}) => PayloadAction<{
    key: K,
    value: CircuitSliceState['inputs'][K],
}>;

export const setPackage = slice.actions.setPackage as <
    K extends keyof CircuitSliceState['package'],
>(payload: {
    key: K,
    value: CircuitSliceState['package'][K],
}) => PayloadAction<{
    key: K,
    value: CircuitSliceState['package'][K],
}>;

export const setPassengersGroups = slice.actions.setPassengersGroups as <
    K extends keyof CircuitSliceState['precart']['passengersGroups'],
>(payload: {
    key: K,
    value: CircuitSliceState['precart']['passengersGroups'][K],
}) => PayloadAction<{
    key: K,
    value: CircuitSliceState['precart']['passengersGroups'][K],
}>;

export const setFlightsSearchPage = slice.actions.setFlightsSearchPage as <
    K extends keyof CircuitSliceState['flightsSearchPage'],
>(payload: {
    key: K,
    value: CircuitSliceState['flightsSearchPage'][K],
}) => PayloadAction<{
    key: K,
    value: CircuitSliceState['flightsSearchPage'][K],
}>;
